@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  
  body {
    @apply text-noble-gray bg-noble-white;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply text-noble-navy font-bold;
  }
}

@layer components {
  .btn-primary {
    @apply bg-noble-blue text-noble-white px-6 py-3 rounded-lg font-semibold 
           hover:bg-opacity-90 transition-all duration-300 ease-in-out;
  }

  .btn-primary-elegant {
    @apply bg-noble-blue text-noble-white px-5 py-2.5 rounded-full font-medium
           shadow-sm hover:shadow-noble-blue/20 hover:bg-opacity-95
           transition-all duration-300 ease-in-out;
  }

  .btn-secondary {
    @apply border-2 border-noble-blue text-noble-blue px-6 py-3 rounded-lg font-semibold 
           hover:bg-noble-blue hover:text-noble-white transition-all duration-300 ease-in-out;
  }

  .section-container {
    @apply container mx-auto px-4 py-16 sm:px-6 lg:px-8;
  }
}
